export default {
      ok: 'ok',
      startAnswering:'start answering',
      explain:'explain',
      option:'option ',
      number:' number ',
      question:' ',
      total:'total ',
      previousQuestion:'Previous question',
      nextQuestion:'Next question',
      prevQuestion:'Previous question',
      submit:'Submit questionnaire',
      choose:'Please select an answer',
      singleDimension:'single dimension',
      multipleDimensions:'multiple dimensions',
      startTips:'You have answered some questions before, do you want to continue your answer?',
      tips:'tips',
      cancel:'cancel',
      end:'Answer successfully',
      thanksEnd:'Thank you for your patience'
  };
 