export default {
    
      ok: '确认',
      startAnswering:'开始答题',
      explain:'说明',
      option:'选项',
      number:' 第',
      question:'题',
      total:'共',
      prevQuestion:'上一题',
      nextQuestion:'下一题',
      submit:'提交问卷',
      choose:'请选择答案',
      singleDimension:'单维度',
      multipleDimensions:'多维度',
      startTips:'您之前已经回答了部分题目, 是否继续上次回答?',
      tips:'提示',
      cancel:'取消',
      end:'答题成功',
      thanksEnd:'感谢您的耐心参与'
  };
 