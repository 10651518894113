import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { i18n } from './locales';
import './assets/editor/quill.bubble.css'
import './assets/editor/quill.snow.css'
import './assets/editor/quill.bubble.css'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import './assets/my.css'


const app = createApp(App);
app.use(router);
app.use(store);
app.use(i18n);
app.use(ElementPlus);

app.mount('#app');
