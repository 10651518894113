import { createI18n } from 'vue-i18n';
import zh from './zh-CN';
import en from './en-US';

const defaultLanguage = 'zh-CN';

const i18n = createI18n({
  locale: defaultLanguage,
  messages: { zh, en },
});

export { i18n };
