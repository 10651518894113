const getters = {
    userId: (state) => state.user.id,
    email: (state) => state.user.email,
    phone: (state) => state.user.phone,
    avatar: (state) => state.user.avatar,
    nickname: (state) => state.user.nickname,
    role: (state) => state.user.role,
  };
  
  export default getters;
 