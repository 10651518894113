<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
}
</script>
<style>
  html,body{
    height: 100%;
  }
  *{
    margin: 0;
    padding: 0;
  }
  #app{
    height: 100%;
  }
  *{
    margin: 0;
    padding:0;
  }
  /* .el-button--primary{
    background-color: #D20032 !important;
    border:1px solid #D20032 !important;
  }
  .el-radio{
    border-color:#D20032 !important;
    
  }
  .el-radio__input.is-checked+.el-radio__label{
    color:#D20032 !important;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: #D20032 !important;
    background: #D20032 !important;
}
.el-checkbox__input.is-checked .el-checkbox__inner{
    background-color: #D20032 !important;
    border-color: #D20032 !important;
  }
.el-checkbox__input.is-checked+.el-checkbox__label{
  color:#D20032 !important;
} */
</style>