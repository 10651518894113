const user = {
    // 声明变量
    state: {
      id: '',
      email: '',
      phone: '',
      nickname: '',
      avatar: '',
      role: {},
    },
    // 修改变量（state不能直接赋值修改，只能通过mutations）
    mutations: {
      // 参数一：state，参数二：新值
      SET_USERID: (state, id) => {
        state.id = id;
      },
      SET_EMAIL: (state, email) => {
        state.email = email;
      },
      SET_PHONE: (state, phone) => {
        state.phone = phone;
      },
      SET_NAME: (state, nickname) => {
        state.nickname = nickname;
      },
      SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
      },
      SET_USER_ROLE: (state, role) => {
        state.role = role;
      },
    },
    // mutations的值由actions传入
    actions: {
      GET_USER_INFO({ commit }, userInfo) {
        commit('SET_USERID', userInfo.id);
        commit('SET_EMAIL', userInfo.email);
        commit('SET_PHONE', userInfo.phone);
        commit('SET_NAME', userInfo.name);
        commit('SET_AVATAR', userInfo.avatar);
        commit('SET_USER_ROLE', userInfo.role);
      }
    },
  };
  
  export default user;
